{
    "next_coms": "Következő hozzászólások",
    "ago": "{{duration}} ezelött",
    "groups": "Csoportok",
    "new_password_placeholder": "új jelszó",
    "connect": "Kapcsolat",
    "submit": "Küldés",
    "title_placeholder": "Üzenet címe (nem kötelező)",
    "login_placeholder": "email",
    "previous_coms": "Korábbi hozzászólások",
    "password_placeholder": "jelszó",
    "confirm_password_placeholder": "jelszó megerősítése",
    "text_placeholder": "Írd ide az üzenetet",
    "upload_image": "Fotók hozzáadása",
    "upload_video": "Videó hozzáadása",
    "upload_music": "Zenék hozzáadása",
    "upload_pdf": "Pdf hozzáadása",
    "add_date": "Dátum hozzáadása",
    "logout": "Kijelentkezés",
    "name_input": "Válassz egy nevet",
    "login": "Bejelentkezés",
    "login_input": "Válassz ki egy bejelentkezést",
    "email": "Email",
    "email_input": "Írd az email címedet ide",
    "save_changes": "Változások mentése",
    "password": "Jelszó",
    "Invalid login/password": "Hibás felhasználó és/vagy jelszó",
    "delete": "Törlés",
    "account": "Fiók",
    "forgot_password": "Elfelejtetted a jelszavad?",
    "password_reset_mail_sent": "A jelszóhelyreállító email el lett küldve!",
    "User not found": "Felhasználó nem található",
    "reset_password_title": "Jelszó alaphelyzetbe állítás",
    "error": "Hiba történt",
    "invitation_link": "Meghívó link",
    "invitation_welcome": "Üdvözöl a Zusam ! Regisztrálj mielött belépsz egy csoportba.",
    "signup": "Regisztráció",
    "create_a_group": "Egy csoport létrehozása",
    "create_the_group": "A csoport létrehozása",
    "quit_group": "Kilépés a csoportból",
    "group_updated": "A csoport frissítve !",
    "group_left": "Elhagytad a csoportot !",
    "error_new_message": "Hiba történt az üzenet küldése közben.",
    "error_upload": "Hiba történt a feltöltés közben",
    "empty_message": "Az üzenet üres és így nem küldhető el !",
    "cancel": "Mégsem",
    "just_now": "Csak most",
    "notification_emails": "Email értesítések",
    "none": "Egyik sem",
    "hourly": "Óránként",
    "daily": "Napi",
    "weekly": "Heti",
    "monthly": "Havi",
    "notification_emails_stopped": "Az e-mail értesítések leálltak !",
    "public_link": "nyilvános link",
    "default_group": "Alapértelmezett csoport",
    "destroy_account": "A fiók törlése",
    "are_you_sure": "Biztos vagy benne?",
    "share_message": "Az üzenet megosztása",
    "users": "felhasználók",
    "has_posted_in": "közzétéve",
    "has_commented_on": "hozzászolt",
    "has_joined": "csatlakozott",
    "has_left": "kilépett",
    "in": "ban",
    "lang": "nyelv",
    "reply": "válasz",
    "replies_other": "{{count}} válasz",
    "search_in_group": "keresés ebben a csoportban",
    "changed_group_name": "megváltoztatta a csoport nevét",
    "mark_all_as_read": "összes megjelölése olvasottként",
    "search_without_result": "A keresés nem egyezett egyetlen üzenettel sem.",
    "bookmarks": "könyvjelzők",
    "add_bookmark": "Üzenet elrakása a könyvjelzőkhöz",
    "api_key": "API kulcs",
    "reset_api_key": "Az API-kulcs alaphelyzetbe állítása (megszakad a kapcsolat)",
    "video_not_ready": "A videó még nem áll készen.",
    "bookmark_added": "Üzenet hozzáadva a könyvjelzőkhöz !",
    "bookmark_removed": "Az üzenet eltávolítva a könyvjelzők közül!",
    "parent_message": "Szülő üzenet",
    "settings": "Beállítások",
    "name": "Név",
    "password_input": "Írd ide az új jelszót",
    "edit": "Szerkesztés",
    "ask_delete_message": "Biztos, hogy törlöd az üzenetet?",
    "passwords_dont_match": "A jelszavak nem egyeznek",
    "reset_invitation_link": "Meghívó link alaphelyzetbe állítása",
    "settings_updated": "A beállítások frissültek",
    "multiple_photos_upload": "Hiba történt. Próbáld a képeket egyesével feltölteni ( néhány böngészőben ez történhet ).",
    "cancel_write": "Biztos, hogy megszakítód az új üzeneted írását?",
    "group_share_choice": "Melyik csoportnak szeretné elküldeni ezt az üzenetet?",
    "destroy_account_explain": "Miután törölte a fiókját, nincs visszaút. Kérlek, légy biztos.",
    "notifications": "értesítések",
    "the_message_from": "az üzenet küldője",
    "replies_one": "{{count}} válasz",
    "publish_in_group": "közzététel a csoportban",
    "to": "hoz",
    "remove_bookmark": "Üzenet könyvjelzőjének törlése",
    "upload_audio": "",
    "audio_not_ready": ""
}
