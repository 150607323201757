{
    "password_placeholder": "密码",
    "title_placeholder": "您消息的标题(可选)",
    "upload_image": "添加图片",
    "text_placeholder": "在这里输入您的消息",
    "upload_music": "添加音乐",
    "upload_pdf": "添加PDF文件",
    "add_date": "添加日期",
    "logout": "注销",
    "settings": "设置",
    "name": "姓名",
    "name_input": "选择一个名称",
    "login": "登录",
    "login_input": "选择一个以登录",
    "email": "电子邮箱",
    "email_input": "在这里输入您的电子邮件地址",
    "passwords_dont_match": "密码不一致",
    "invitation_link": "邀请链接",
    "reset_invitation_link": "重设邀请链接",
    "invitation_welcome": "欢迎来到Zusam ! 在加入一个组前,请先注册。",
    "signup": "注册",
    "create_a_group": "创建群组",
    "create_the_group": "创建群组",
    "quit_group": "离开群组",
    "settings_updated": "你的设置已更新",
    "group_left": "你离开了群组!",
    "group_updated": "群组已更新!",
    "multiple_photos_upload": "发生错误。 请尝试逐个上传图像(在某些浏览器中可能会发生这种情况)。",
    "error_new_message": "发送消息时出错。",
    "error_upload": "上传出错",
    "cancel_write": "是否确实要取消编写新消息?",
    "empty_message": "无法发送空消息!",
    "notification_emails": "电子邮件通知",
    "destroy_account_explain": "一旦您删除了账户,您将无法撤销本操作! 请确认。",
    "replies_one": "{{count}}回复",
    "replies_other": "{{count}}回复了",
    "publish_in_group": "在群组里发布",
    "search_in_group": "在此群组中搜索",
    "changed_group_name": "更改了组的名称",
    "to": "到",
    "notifications": "通知",
    "mark_all_as_read": "全部已读",
    "search_without_result": "没有匹配的消息。",
    "add_bookmark": "为消息添加书签",
    "previous_coms": "查看以前的评论",
    "next_coms": "查看下一个评论",
    "ago": "{{duration}}前",
    "groups": "组",
    "login_placeholder": "电子邮箱",
    "new_password_placeholder": "新密码",
    "confirm_password_placeholder": "确认密码",
    "connect": "连接",
    "submit": "提交",
    "save_changes": "保存更改",
    "password": "密码",
    "password_input": "在此输入您的的密码",
    "Invalid login/password": "用户名或密码错误",
    "edit": "编辑",
    "delete": "删除",
    "account": "账户",
    "forgot_password": "忘记你的密码了吗?",
    "password_reset_mail_sent": "密码重置邮件已发送!",
    "User not found": "用户不存在",
    "reset_password_title": "重置您的密码",
    "error": "出错了",
    "ask_delete_message": "你确定要删除此消息吗?",
    "cancel": "取消",
    "just_now": "刚刚",
    "none": "无",
    "hourly": "每小时",
    "daily": "每天",
    "weekly": "每周",
    "monthly": "每月",
    "notification_emails_stopped": "已停用邮件提醒!",
    "public_link": "公共链接",
    "default_group": "默认群组",
    "destroy_account": "删除您的账户",
    "are_you_sure": "您确定吗?",
    "share_message": "分享此消息",
    "users": "用户",
    "has_posted_in": "已发布于",
    "has_commented_on": "评论于",
    "has_joined": "已加入",
    "has_left": "已退出",
    "the_message_from": "消息来自",
    "in": "里",
    "lang": "语言",
    "reply": "回复",
    "remove_bookmark": "为消息移除书签",
    "bookmarks": "书签",
    "api_key": "API密钥",
    "reset_api_key": "重置API密钥(您将会断开连接)",
    "video_not_ready": "视频尚未准备好。",
    "bookmark_added": "消息已添加到书签!",
    "bookmark_removed": "消息已从书签中移除!",
    "parent_message": "父消息",
    "group_share_choice": "您想将此消息发送到哪个群组?",
    "audio_not_ready": "音频尚未准备好。",
    "upload_video": "添加视频",
    "upload_audio": "添加音频文件。"
}
