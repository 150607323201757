{
    "previous_coms": "Voir les commentaires précédents",
    "next_coms": "Voir les commentaires suivants",
    "ago": "Il y a {{duration}}",
    "groups": "Groupes",
    "login_placeholder": "courriel",
    "password_placeholder": "mot de passe",
    "new_password_placeholder": "nouveau mot de passe",
    "confirm_password_placeholder": "confirmez le mot de passe",
    "connect": "Connexion",
    "submit": "Envoyer",
    "title_placeholder": "Titre de votre message (optionnel)",
    "text_placeholder": "Écrivez votre message ici",
    "upload_image": "Ajouter des photos",
    "upload_video": "Ajouter une vidéo",
    "upload_music": "Ajouter des musiques",
    "upload_pdf": "Ajouter un pdf",
    "add_date": "Préciser une date",
    "logout": "Se déconnecter",
    "settings": "Paramètres",
    "name": "Nom",
    "name_input": "Choisissez un nom",
    "login": "Identifiant",
    "login_input": "Choisissez un identifiant",
    "email": "Courriel",
    "email_input": "Saisissez votre adresse électronique ici",
    "save_changes": "Sauvegarder les changements",
    "password": "Mot de passe",
    "password_input": "Entrez un nouveau mot de passe",
    "Invalid login/password": "Identifiant et/ou mot de passe invalide",
    "edit": "Modifier",
    "delete": "Supprimer",
    "ask_delete_message": "Êtes-vous sûr de vouloir supprimer ce message ?",
    "account": "Compte",
    "forgot_password": "Mot de passe oublié ?",
    "password_reset_mail_sent": "Le courriel de réinitialisation a bien été envoyé !",
    "User not found": "Utilisateur inexistant",
    "reset_password_title": "Réinitialisation de votre mot de passe",
    "error": "Une erreur s'est produite",
    "passwords_dont_match": "Les mots de passe ne correspondent pas",
    "invitation_link": "Lien d'invitation",
    "reset_invitation_link": "Réinitialiser le lien d'invitation",
    "invitation_welcome": "Bienvenue sur Zusam ! Veuillez vous inscrire avant de rejoindre le groupe.",
    "signup": "S'inscrire",
    "create_a_group": "Créer un groupe",
    "create_the_group": "Créer le groupe",
    "quit_group": "Quitter le groupe",
    "settings_updated": "Vos paramètres ont été mis à jour",
    "group_updated": "Le groupe a été mis à jour !",
    "group_left": "Vous avez quitté le groupe !",
    "multiple_photos_upload": "Une erreur s'est produite. Essayez de télécharger les images une par une (cela peut se produire dans certains navigateurs).",
    "error_new_message": "Une erreur s’est produite lors de l’envoi de votre message.",
    "error_upload": "Une erreur s'est produite pendant le téléversement",
    "cancel_write": "Voulez-vous annuler l'écriture d'un nouveau message ?",
    "empty_message": "Le message est vide et ne peut être envoyé !",
    "cancel": "Annuler",
    "just_now": "À l'instant",
    "notification_emails": "Courriel de notification",
    "none": "Aucun",
    "hourly": "Une fois par heure",
    "daily": "Journalier",
    "weekly": "Hebdomadaire",
    "monthly": "Mensuel",
    "notification_emails_stopped": "Notifications par courriel désactivées !",
    "group_share_choice": "A quel groupe voulez-vous envoyer ce message ?",
    "public_link": "lien public",
    "default_group": "Groupe par défaut",
    "destroy_account": "Supprimer votre compte",
    "destroy_account_explain": "Une fois votre compte supprimé, il sera impossible d'annuler l'opération.",
    "are_you_sure": "Êtes-vous sûr ?",
    "share_message": "partager le message",
    "users": "utilisateurs",
    "has_posted_in": "a posté dans",
    "has_commented_on": "a commenté",
    "has_joined": "a rejoint",
    "has_left": "a quitté",
    "the_message_from": "le message de",
    "in": "dans",
    "lang": "langue",
    "reply": "répondre",
    "replies_one": "{{count}} réponse",
    "replies_other": "{{count}} réponses",
    "publish_in_group": "promouvoir le message",
    "search_in_group": "rechercher dans le groupe",
    "changed_group_name": "a changé le nom du groupe",
    "to": "en",
    "notifications": "notifications",
    "mark_all_as_read": "tout marquer comme lu",
    "search_without_result": "Votre recherche ne correspond à aucun message.",
    "add_bookmark": "Ajouter aux marque-pages",
    "remove_bookmark": "Supprimer des marque-pages",
    "bookmarks": "marque-pages",
    "api_key": "Clef d'API",
    "reset_api_key": "Changer votre clef d'API (vous allez être déconnecté)",
    "video_not_ready": "La vidéo n'est pas encore prête.",
    "bookmark_added": "Message ajouté aux marque-pages !",
    "bookmark_removed": "Message supprimé des marque-pages !",
    "parent_message": "Message parent",
    "audio_not_ready": "Le fichier audio n'est pas prêt.",
    "upload_audio": "Ajouter un fichier audio."
}
