{
    "edit": "Bearbeiten",
    "add_date": "Ein Datum hinzufügen",
    "save_changes": "Änderungen speichern",
    "password_input": "Gib hier ein neues Passwort ein",
    "next_coms": "Nächste Kommentare ansehen",
    "previous_coms": "Vorherige Kommentare ansehen",
    "ago": "Vor {{duration}}",
    "submit": "Senden",
    "groups": "Gruppen",
    "password_placeholder": "Passwort",
    "title_placeholder": "Titel deiner Nachricht (optional)",
    "text_placeholder": "Schreibe deine Nachricht hier",
    "upload_video": "Video hinzufügen",
    "login_placeholder": "E-Mail",
    "new_password_placeholder": "Neues Passwort",
    "upload_music": "Musik hinzufügen",
    "upload_pdf": "PDF hinzufügen",
    "confirm_password_placeholder": "Passwort bestätigen",
    "delete": "Löschen",
    "upload_image": "Fotos hinzufügen",
    "logout": "Abmelden",
    "name": "Name",
    "connect": "Anmelden",
    "name_input": "Wähle einen Namen",
    "settings": "Einstellungen",
    "login_input": "Wähle einen Benutzername",
    "email": "E-Mail",
    "login": "Benutzername",
    "email_input": "Gib hier deine E-Mail-Adresse ein",
    "password": "Passwort",
    "Invalid login/password": "Ungültiges Benutzername oder Passwort",
    "forgot_password": "Hast du dein Passwort vergessen?",
    "ask_delete_message": "Bist du sicher, dass du diese Nachricht löschen willst?",
    "account": "Konto",
    "password_reset_mail_sent": "Die E-Mail zum Zurücksetzen des Passworts wurde gesendet!",
    "create_a_group": "Eine Gruppe erstellen",
    "users": "Benutzer",
    "to": "zu",
    "remove_bookmark": "Nachricht aus den Lesezeichnen entfernen",
    "bookmarks": "Lesezeichen",
    "multiple_photos_upload": "Ein Fehler ist aufgetreten. Versuche, die Bilder nacheinander hochzuladen (dies kann bei einigen Browsern vorkommen).",
    "passwords_dont_match": "Passwörter stimmen nicht überein",
    "User not found": "Benutzer nicht gefunden",
    "reset_password_title": "Dein Passwort zurücksetzen",
    "error": "Ein Fehler ist aufgetreten",
    "invitation_link": "Einladungslink",
    "reset_invitation_link": "Einladungslink zurücksetzen",
    "invitation_welcome": "Willkommen bei Zusam! Bitte melde dich an, bevor du der Gruppe beitrittst.",
    "notification_emails_stopped": "E-Mail-Benachrichtigungen gestoppt!",
    "the_message_from": "die Nachricht von",
    "signup": "Registrieren",
    "group_updated": "Die Gruppe wurde aktualisiert!",
    "group_left": "Du hast die Gruppe verlassen!",
    "create_the_group": "Die Gruppe erstellen",
    "quit_group": "Die Gruppe verlassen",
    "settings_updated": "Deine Einstellungen wurden aktualisiert",
    "monthly": "Monatlich",
    "error_new_message": "Beim Senden deiner Nachricht ist ein Fehler aufgetreten.",
    "just_now": "Gerade jetzt",
    "none": "Keine",
    "weekly": "Wöchentlich",
    "are_you_sure": "Bist du sicher?",
    "error_upload": "Beim Hochladen ist ein Fehler aufgetreten",
    "cancel_write": "Willst du das Schreiben einer neuen Nachricht wirklich abbrechen?",
    "empty_message": "Die Nachricht ist leer und kann nicht gesendet werden!",
    "cancel": "Abbrechen",
    "notification_emails": "E-Mail-Benachrichtigungen",
    "hourly": "Stündlich",
    "daily": "Täglich",
    "group_share_choice": "An welche Gruppe möchtest du diese Nachricht senden?",
    "destroy_account": "Dein Konto löschen",
    "has_left": "hat verlassen",
    "publish_in_group": "in der Gruppe veröffentlichen",
    "public_link": "öffentlicher Link",
    "default_group": "Standard-Gruppe",
    "has_posted_in": "hat veröffentlicht in",
    "has_joined": "ist beigetreten",
    "lang": "Sprache",
    "reply": "antworten",
    "destroy_account_explain": "Wenn du dein Konto einmal gelöscht hast, gibt es keinen Weg zurück. Bitte sei sicher.",
    "share_message": "die Nachricht teilen",
    "search_in_group": "in dieser Gruppe suchen",
    "mark_all_as_read": "alle als gelesen markieren",
    "has_commented_on": "hat kommentiert",
    "in": "in",
    "replies_one": "{{count}} Antwort",
    "replies_other": "{{count}} Antworten",
    "changed_group_name": "hat den Gruppenamen geändert",
    "notifications": "Benachrichtigungen",
    "reset_api_key": "Setze den API-Schlüssel zurück (die Verbindung wird unterbrochen)",
    "video_not_ready": "Das Video ist noch nicht fertig.",
    "bookmark_added": "Nachricht zu den Lesezeichen hinzugefügt!",
    "search_without_result": "Deine Suche hat zu keiner Nachricht geführt.",
    "add_bookmark": "Nachricht zu den Lesezeichnen hinzufügen",
    "api_key": "API-Schlüssel",
    "bookmark_removed": "Nachricht aus den Lesezeichen entfernt!",
    "parent_message": "Quellenachricht",
    "audio_not_ready": "Audio ist noch nicht fertig.",
    "upload_audio": "Audio hinzufügen."
}
