{
    "previous_coms": "Ver los comentarios anteriores",
    "next_coms": "Ver los siguientes comentarios",
    "ago": "Hace {{duration}}",
    "groups": "Grupos",
    "login_placeholder": "correo",
    "password_placeholder": "contraseña",
    "new_password_placeholder": "nueva contraseña",
    "confirm_password_placeholder": "confirmar contraseña",
    "connect": "Conectarse",
    "submit": "Enviar",
    "title_placeholder": "Asunto (opcional)",
    "text_placeholder": "Escribe tu mensaje aquí",
    "upload_image": "Añadir fotos",
    "upload_video": "Añadir video",
    "upload_music": "Añadir música",
    "upload_pdf": "Añadir pdf",
    "add_date": "Añadir una fecha",
    "logout": "Desconectarse",
    "settings": "Parámetros",
    "name": "Nombre",
    "name_input": "Elige un nombre",
    "login": "Usuario",
    "login_input": "Elige un usuario",
    "email": "Correo",
    "email_input": "Indicar correo electrónico",
    "save_changes": "Guardar los cambios",
    "password": "Contraseña",
    "password_input": "Indicar una nueva contraseña",
    "Invalid login/password": "Usuario o contraseña incorrecto",
    "edit": "Editar",
    "delete": "Borrar",
    "ask_delete_message": "¿Quieres borrar este mensaje?",
    "account": "Cuenta",
    "forgot_password": "¿Olvidaste tu contraseña?",
    "password_reset_mail_sent": "El correo ha sido enviado!",
    "User not found": "El usuario no existe",
    "reset_password_title": "Cambia tu contraseña",
    "error": "Se ha producido un error",
    "passwords_dont_match": "Las contraseñas no coinciden",
    "invitation_link": "Enlace de invitación",
    "reset_invitation_link": "Cambiar el enlace de invitación",
    "invitation_welcome": "Bienvenido a Zusam ! Inscribete para conectarte con un grupo.",
    "signup": "Inscribirse",
    "create_a_group": "Crear un grupo",
    "create_the_group": "Crear el grupo",
    "quit_group": "Dejar el grupo",
    "settings_updated": "Sus ajustes fueron actualizados",
    "group_updated": "El grupo ha sido actualizado!",
    "group_left": "¡Has dejado el grupo!",
    "multiple_photos_upload": "Se ha producido un error. Intenta subir las imágenes una a una (esto puede ocurrir en algunos navegadores)",
    "error_new_message": "Se ha producido un error al enviar su mensaje.",
    "error_upload": "Se ha producido un error durante la carga",
    "cancel_write": "¿Quieres cancelar este nuevo mensaje?",
    "empty_message": "No hay mensaje!",
    "cancel": "Cancelar",
    "just_now": "Ahora",
    "notification_emails": "Correo de notificación",
    "none": "Ninguno",
    "hourly": "Cada hora",
    "daily": "Diario",
    "weekly": "Semanal",
    "monthly": "Mensual",
    "notification_emails_stopped": "Notificaciones de correo electrónico detenidas!",
    "group_share_choice": "¿A qué grupo desea enviar este mensaje?",
    "public_link": "enlace público",
    "default_group": "Grupo predeterminado",
    "destroy_account": "Borrar la cuenta",
    "destroy_account_explain": "Una vez que elimines tu cuenta, no hay vuelta atrás.",
    "are_you_sure": "Estás seguro ?",
    "share_message": "comparte el mensaje",
    "users": "usuarios",
    "has_posted_in": "ha publicado en",
    "has_commented_on": "ha comentado",
    "has_joined": "se has unido",
    "has_left": "ha dejado",
    "the_message_from": "el mensaje de",
    "in": "en",
    "lang": "idioma",
    "reply": "responder",
    "replies_one": "{{count}} respuesta",
    "replies_other": "{{count}} respuestas",
    "publish_in_group": "publicar en el grupo",
    "search_in_group": "buscar en este grupo",
    "changed_group_name": "cambió el nombre del grupo",
    "to": "a",
    "notifications": "notificaciones",
    "mark_all_as_read": "marcar todo como leido",
    "search_without_result": "Su búsqueda no coincide con ningún mensaje.",
    "add_bookmark": "Marcar este mensaje",
    "remove_bookmark": "Desmarcar este mensaje",
    "bookmarks": "marcadores",
    "api_key": "Clave API",
    "reset_api_key": "Restablecer la clave API (se desconectará)",
    "video_not_ready": "El vídeo aún no está listo.",
    "bookmark_added": "Mensaje agregado a marcadores !",
    "bookmark_removed": "Mensaje eliminado de los marcadores !",
    "parent_message": "Mensaje para padres",
    "audio_not_ready": "El audio aún no está listo.",
    "upload_audio": "Añadir un archivo de audio."
}
