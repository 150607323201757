{
    "groups": "그룹",
    "new_password_placeholder": "새로운 비밀번호",
    "confirm_password_placeholder": "비밀번호 확인",
    "title_placeholder": "메시지 제목 (선택사항)",
    "upload_pdf": "PDF 추가",
    "password": "비밀번호",
    "password_input": "여기에 새로운 비밀번호 작성",
    "password_reset_mail_sent": "비밀번호 재설정 이메일이 전송되었습니다!",
    "settings_updated": "설정 업데이트됨",
    "notification_emails_stopped": "이메일 알림이 중지되었습니다!",
    "public_link": "공개 링크",
    "has_commented_on": "댓글 등록됨",
    "the_message_from": "메세지",
    "previous_coms": "이전 댓글 보기",
    "next_coms": "다음 댓글 보기",
    "ago": "{{duration}} 전",
    "name_input": "이름 선택",
    "login_placeholder": "이메일",
    "submit": "제출",
    "add_date": "날짜 추가",
    "name": "이름",
    "password_placeholder": "비밀번호",
    "upload_music": "음악 추가",
    "logout": "로그아웃",
    "connect": "연결",
    "text_placeholder": "여기에 메시지 작성",
    "upload_video": "동영상 추가",
    "settings": "설정",
    "login": "로그인",
    "login_input": "로그인 선택",
    "upload_image": "사진 추가",
    "save_changes": "변경 사항 저장",
    "email": "이메일",
    "email_input": "여기에 이메일 입력",
    "Invalid login/password": "잘못된 로그인 및/또는 비밀번호",
    "edit": "편집",
    "delete": "삭제",
    "ask_delete_message": "이 메시지를 삭제하겠습니까?",
    "account": "계정",
    "forgot_password": "비밀번호를 잊었나요?",
    "signup": "가입하기",
    "notification_emails": "이메일 알림",
    "none": "없음",
    "User not found": "사용자를 찾을 수 없음",
    "reset_password_title": "비밀번호 재설정",
    "invitation_link": "초대 링크",
    "reset_invitation_link": "초대 링크 재설정",
    "error": "오류 발생",
    "passwords_dont_match": "비밀번호가 일치하지 않음",
    "invitation_welcome": "Zusam에 오신 것을 환영합니다! 그룹에 가입하기 전에 가입하세요.",
    "create_a_group": "그룹 만들기",
    "daily": "일간",
    "create_the_group": "그룹 만들기",
    "quit_group": "그룹 나가기",
    "group_updated": "그룹이 업데이트되었습니다!",
    "multiple_photos_upload": "오류가 발생했습니다. 이미지를 하나씩 업로드해 보세요 (일부 브라우저에서 발생할 수 있음).",
    "group_left": "그룹을 나갔습니다!",
    "error_new_message": "메시지를 보내는 동안 오류가 발생했습니다.",
    "empty_message": "메시지가 비어 있어 보낼 수 없습니다!",
    "default_group": "기본 그룹",
    "error_upload": "업로드 중 오류 발생",
    "weekly": "주간",
    "monthly": "월간",
    "cancel_write": "새로운 메시지 작성을 취소하겠습니까?",
    "cancel": "취소",
    "group_share_choice": "이 메시지를 어느 그룹에 보내겠습니까?",
    "hourly": "매 시간",
    "just_now": "지금 당장",
    "users": "사용자",
    "has_posted_in": "게시됨",
    "destroy_account": "계정 삭제",
    "destroy_account_explain": "계정을 삭제하면 되돌릴 수 없습니다. 확인 해 주세요.",
    "are_you_sure": "확실하나요?",
    "share_message": "메시지 공유",
    "has_joined": "가입됨",
    "lang": "언어",
    "reply": "답글",
    "replies_one": "{{count}} 답글",
    "publish_in_group": "그룹에 게시",
    "search_in_group": "이 그룹에서 검색",
    "to": "에",
    "search_without_result": "검색어와 일치하는 메시지가 없습니다.",
    "bookmarks": "북마크",
    "video_not_ready": "동영상이 아직 준비되지 않았습니다.",
    "parent_message": "상위 메시지",
    "in": "의",
    "changed_group_name": "그룹 이름 변경됨",
    "notifications": "알림",
    "mark_all_as_read": "모두 읽은 것으로 표시",
    "reset_api_key": "API 키 재설정 (연결이 끊어짐)",
    "has_left": "나갔음",
    "replies_other": "{{count}} 답글",
    "remove_bookmark": "북마크 해제 메시지",
    "api_key": "API 키",
    "bookmark_added": "북마크에 메시지가 추가되었습니다!",
    "bookmark_removed": "북마크에서 메시지가 제거되었습니다!",
    "add_bookmark": "북마크 메시지",
    "audio_not_ready": "오디오가 아직 준비되지 않았습니다.",
    "upload_audio": "오디오 파일을 추가합니다."
}
